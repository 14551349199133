body {
    .ant-notification {
        z-index: 99999;
    }

    .ant-notification-notice {
        padding: 15px 50px 15px 24px;
        border-radius: $alert-border-radius;
        font-weight: $alert-link-font-weight;
        border: $alert-border-width solid;
        font-size: $font-size-base;
        line-height: $line-height-base;
        width: 340px;
        box-shadow: none;

        &.ant-notification-notice-success {
            border-color: #10B981;
            background-color: $lt-green2;
            color: #047857;

            .ant-notification-notice-message,
            .ant-notification-notice-description {
                max-height: 200px;
                overflow: auto;
                color: inherit;
            }

            ::-webkit-scrollbar-thumb {
                background: #10B981;
            }
        }

        &.ant-notification-notice-info {
            border-color: #109db9;
            background-color: $lt-info;
            color: #045f78;

            .ant-notification-notice-message,
            .ant-notification-notice-description {
                max-height: 200px;
                overflow: auto;
                color: inherit;
            }

            ::-webkit-scrollbar-thumb {
                background: #109db9;
            }
        }

        &.ant-notification-notice-warning {
            border-color: #b98110;
            background-color: $lt-warning;
            color: #785704;

            .ant-notification-notice-message,
            .ant-notification-notice-description {
                max-height: 200px;
                overflow: auto;
                color: inherit;
            }

            ::-webkit-scrollbar-thumb {
                background: #b98110;
            }
        }

        &.ant-notification-notice-error {
            border-color: $red;
            background-color: $lt-danger;
            color: $red;

            .ant-notification-notice-message,
            .ant-notification-notice-description {
                max-height: 200px;
                overflow: auto;
                color: inherit;
            }

            ::-webkit-scrollbar-thumb {
                background: $red;
            }
        }

        .ant-notification-notice-message {
            margin-bottom: 0;
        }

        .ant-notification-notice-with-icon {

            .ant-notification-notice-message,
            .ant-notification-notice-description {
                margin-left: 40px;
                font-size: inherit;
            }
        }

        .ant-notification-notice-icon {
            margin-left: 0;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: inherit;
            top: $alert-padding-y;
        }

        .ant-notification-notice-close {
            top: $alert-padding-y;
            right: $alert-padding-x;
            color: $body-color;
            font-size: 13px;
        }

        .ant-notification-notice-close-x {
            display: flex;
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;

            &:before {
                content: "\e09b";
                font-family: "moneta";
            }

            span {
                display: none;
            }
        }

        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-track {
            background: unset;
            border-radius: 15px;
        }
    
        ::-webkit-scrollbar-thumb {
            background: #41BA77;
            // background: #284254 !important;
            border-radius: 15px;
            border: unset
        }
    }
}
