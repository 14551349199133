.beta-tag {
    background-color: $lt-green;
    color: $ltdk-green;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 24px;
    position: absolute;
    top: 5%;
    right: 25%;
}
.twilio-conversations-container {

    .twilio-conversations-header {
        position: relative;
        
        .advisor-number {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #41BA77;
            padding: 0 10px;
            border-bottom: 1px solid #284254;
            border-left: 1px solid #284254;
            border-right: 1px solid #284254;
            border-radius: 0 0 10px 10px;
            color: #fff;
        }
    }
}
.twilio-conversations-list {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    border-right: 1px solid #E9EAEE;

    .conversations-header {
        width: 320px;
        z-index: 11;
        background-color: #fff;
        padding: 16px 24px;
        border-bottom: 1px solid #E9EAEE;
        height: 10%;
    }
    .conversations-list {
        height: 90%;
        background-color: #fff;
        overflow-y: auto;

        .no-conversations {
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            margin-top: 50%;
            
            > * {
                flex-basis: 100%;
            }
            h5 {
                color: #b5b6b9;
            }
        }
        .conversation-item {
            display: flex;
            padding: 12px 16px;
            border-bottom: 1px solid #F4F4F6;
            cursor: pointer;
            position: relative;
        
            &:hover {
                background-color: #F4F4F6;
            }
            &.active {
                background-color: #D9F1E4;

                &:before {
                    content: "";
                    background-color: #41BA77;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    z-index: 1;
                }
            }
            .conversation-item-image {
                width: 56px;
                height: 56px;
                min-width: 56px;
                border-radius: 100%;
                overflow: hidden;
                background-color: #D3D6DE;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .conversation-item-details {
                flex: 1 1 auto;
                font-family: 'Segoe UI';
                padding-left: 16px;
                width: calc(100% - 104px);
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            
                .dropdown {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 14px;

                    .icon-32:hover {
                        background-color: #94dab3;
                        border-radius: 5px;
                    }
                }
                h6 {
                    font-weight: 600;
                    margin: 0;
                    line-height: 20px;
                    color: $body-color;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: calc(100% - 106px);
                }
                p {
                    line-height: 20px;
                    margin-bottom: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .message-time {
                    color: $gray-500;
                    font-size: 14px;
                    line-height: 14px;
                    white-space: nowrap;
                }
                .last-message-content {
                    margin: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    flex-basis: calc(100% - 30px);
                }
                .unread-message-count {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 20px;
                    width: 20px;
                    height: 20px;
                    line-height: 19px;
                    color: #fff;
                    border-radius: 100%;
                    font-size: 12px;
                    background-color: #399B6F;
                }
            }
        }
    }
}
.twilio-conversations-messages {
    height: 100%;
    background-color: #fff;

    .messages-header {
        height: 10%;
        border-bottom: 1px solid #E9EAEE;
        padding: 16px 24px;

        .conversation-actions {
            span {
                margin-left: 8px;
            }
            .icon-info-circle-outline {
                color: #41BA77;
            }
        }
    }
    .messages-list {
        height: 80%;
        overflow-y: auto;
        padding: 16px 24px;

        .no-messages {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            > * {
                flex-basis: 100%;
                text-align: center;
            }
            > .anticon {
                height: 64px;
                width: 64px;
                color: #41BA77;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }
            > h5 {
                font-size: 1.2rem;
                font-weight: 500;
                color: #b5b6b9;
            }
        }
        .message-item {
            margin: 16px 0;
            
            &.current-user {
                display: flex;
                justify-content: flex-end;

                .message-item-details {
                    display: flex;
                    flex-direction: column;
                    flex-basis: 100%;
                    align-items: flex-end;
                    padding-left: 16px;

                    h6 {
                        flex-basis: 100%;
                    }
                    .message-content {
                        display: flex;
                        align-items: flex-end;
                        max-width: 75%;

                        .message-body-wrapper {
                            background-color: #41BA77;
                            color: #fff;
                            border-radius: 10px;
                            padding: 8px 16px;
                            width: fit-content;
                            margin-left: 8px;

                            .message-body {
                                word-break: break-all;

                                a {
                                    color: #fff;
                                    text-decoration: underline;
                                }
                            }
                        }
                        .message-time {
                            color: #8089b1;
                            font-size: 14px;
                            line-height: 14px;
                            white-space: nowrap;
                        }
                    }
                }
            }
            // .message-item-image {

            // }
            &:not(.current-user) {

                .message-item-details {
                    display: flex;
                    flex-wrap: wrap;
    
                    .message-author {
                        display: flex;
                        align-items: center;
                        flex-basis: 100%;

                        h6 {
                            margin-bottom: 0.5em;
                        }
                        .message-not-opted-in {
                            background-color: #d55151;
                            color: white;
                            border-radius: 15px;
                            padding: 0 8px;
                            margin-left: 1em;
                            margin-bottom: 0.5em
                        }
                    }
                    .message-content {
                        display: flex;
                        align-items: flex-end;
                        max-width: 75%;
    
                        .message-body-wrapper {
                            background-color: #E9EAEE;
                            border-radius: 10px;
                            padding: 8px 16px;
                            width: fit-content;
                            margin-right: 8px;

                            .message-body {
                                word-break: break-all;

                                a {
                                    color: #000;
                                    text-decoration: underline;
                                }
                            }
                        }
                        .message-time {
                            color: #8089b1;
                            font-size: 14px;
                            line-height: 14px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .date-separator {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;

            span {
                width: fit-content;
            }
            .line {
                border-top: 1px solid #E9EAEE;
                margin-left: 8px;
                margin-right: 8px;
                flex: 1 1 auto;
            }
        }
        .pageinator {
            display: flex;
            justify-content: center;
            margin-top: 16px;

            .btn-paginator {
                background-color: #fff;
                border: 1px solid #E9EAEE;
                border-radius: 10px;
                padding: 8px 16px;
                margin: 0 8px;
                cursor: pointer;
            }
        }
        .system-message {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 16px 0;

            > *:first-child {
                display: flex;
                justify-content: center;
                flex-basis: 100%;

                span {
                    background-color: #E9EAEE;
                    border-radius: 10px 10px 0 0;
                    padding: 0 8px;
                }
            }
            > *:not(:first-child) {
                background-color: #878787;
                border-radius: 10px;
                color: #fff;
                opacity: .9;
                padding: 0.25em 1em;
                max-width: 50%;
            }
        }
    }
    .messages-footer {
        display: flex;
        justify-content: space-between;
        height: 10%;
        border-top: 1px solid #E9EAEE;
        padding: 16px 24px;

        .user-input {
            display: flex;
            justify-content: space-between;
            flex-basis: 100%;
            position: relative;

            .text-danger {
                position: absolute;
                top: -15px;
                left: 1.5em;
                background-color: #d5b4b4;
                border-radius: 15px;
            }
            textarea {
                width: 90%;
                height: 100%;
                border: 1px solid #41BA77;
                border-radius: 10px;
                outline: none;
                font-size: 16px;
                padding: 8px;
                word-break: break-word;
                resize: none;
                overflow-y: hidden;

                &.disabled {
                    background-color: #E9EAEE;
                    cursor: not-allowed;
                }
            }
            button {
                width: 8%;
                height: 100%;

                &.disabled {
                    cursor: not-allowed;
                    pointer-events: unset;
                }
            }
            button:hover &:not(.disabled) {
                background-color: #3AA56D;
            }
            button:active &:not(.disabled) {
                background-color: #2E8F5A;
            }
        }
    }
}
.twilio-conversation-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-left: 1px solid #E9EAEE;
    height: 100%;
    min-width: 320px;
    overflow: auto;

    .conversation-info-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E9EAEE;
        padding: 16px 24px;
        height: 10%;
        width: 100%;

        .conversation-title {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .conversation-info {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        padding: 12px 16px;
        height: 90%;
        width: 100%;

        .conversation-image {
            height: 80px;
            width: 100%;
            min-width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            .conversation-initials {
                background-color: #D3D6DE;
                border-radius: 100%;
                height: 80px;
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .conversation-name {
            text-align: center;
            font-weight: 500;
            max-width: 100%;
        }
        > * {
            flex-basis: 100%;
        }
        .conversation-details {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;

            > * {
                flex-basis: 100%;
            }
        }
        .ant-menu {

            .ant-menu-submenu {

                .ant-menu-submenu-title {
                    padding-left: 24px !important;

                    &:hover {
                        color: #41BA77 !important;
                    }
                }
                li.ant-menu-submenu {
                    border-bottom: 1px solid #E9EAEE;

                    .member-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .user-image {
                            display: flex;
                            height: 32px;
                            width: 32px;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                            .user-initials {
                                background-color: #D3D6DE;
                                border-radius: 100%;
                                height: 32px;
                                width: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                        .user-name {

                            &:hover {
                                color: #41BA77 !important;
                            }
                        }
                    }
                }
            }
            .ant-menu-item {
                padding-left: 24px !important;

                &:hover {
                    color: #41BA77 !important;
                }
            }
            .ant-menu-item.disabled {
                color: #b5b6b9 !important;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }
    > * {
        background-color: #fff;
    }
    .twilio-conversation-view-member-info {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        width: 100%;

        .member-info-options {
            display: flex;
            align-items: center;
            height: 10%;
            width: 100%;
            padding: 16px 24px;
            border-bottom: 1px solid #E9EAEE;

            span.icon-32 {
                em {
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .info {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            position: relative;
            height: 90%;
            padding: 16px 24px;
            width: 100%;

            .info-image {
                height: 80px;
                flex-basis: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
                .conversation-initials {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #D3D6DE;
                    border-radius: 100%;
                    height: 80px;
                    width: 80px;
                }
            }
            .info-name, .info-actions, .info-misc {
                flex-basis: 100%;
            }
            .info-actions {

                .info-action {

                    .value {
                        margin-left: 8px;
                    }
                }
            }
            // .info-misc {

            // }
            .info-developer-help.text-participant, .info-developer-help.chat-participant {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-end;
                align-content: flex-end;
                position: absolute;
                bottom: 16px;
                // Styling
                background-color: #41BA77;
                border-radius: 15px 15px 0 0;
                color: #fff;
                padding: 8px 12px;
                width: calc(100% - 36px);
                margin-bottom: -16px;
                word-break: break-all;
                margin-left: -9px;

                .content-container {
                    height: 100%;
                    width: 100%;
                    overflow: auto;
                }
                .content-container > :first-child {
                    font-weight: 700;
                    text-align: center;
                    flex-basis: calc(100% + 16px);
                    border-bottom: 1px solid #E9EAEE;
                }
                .content-container > *:not(:first-child) {
                    margin-top: 8px;
                    display: flex;
                    flex-wrap: wrap;

                    > :first-child {
                        font-weight: 700;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
.twilio-conversation-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-left: 1px solid #E9EAEE;
    height: 100%;
    overflow: auto;
    min-width: 320px;

    .conversation-search-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E9EAEE;
        padding: 16px 24px;
        height: 10%;
        width: 100%;

        .conversation-title {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .conversation-search-results {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        padding: 12px 16px;
        height: 90%;
        width: 100%;

        .search-area {
            height: 10%;
            width: 100%;

            input {
                padding-right: 45px;
                padding-left: 45px;
            }
            .search-btn {
                position: absolute;
                transform: translate(50%, -40%);
                top: 50%;
                left: 0;
            }
            .close-search-btn {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                right: 0;
            }
        }
        .search-results {
            height: 90%;
            width: 100%;
            overflow: auto;

            .search-result-item {
                display: flex;

                .item-image {
                    padding-right: 12px;

                    .user-photo-no-image {
                        background-color: #D3D6DE;
                        border-radius: 100%;
                        height: 36px;
                        width: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    img {
                        height: 36px;
                        width: 36px;
                        object-fit: cover;
                        border-radius: 100%;
                    }
                }
                .item-details {
                    display: flex;
                    flex: 1 1 auto;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .details-header {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        flex-basis: 100%;

                        h6 {
                            font-weight: 600;
                            margin: 0;
                            line-height: 20px;
                            color: $body-color;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: calc(100% - 106px);
                        }
                        .message-time {
                            color: $gray-500;
                            font-size: 14px;
                            line-height: 14px;
                            white-space: nowrap;
                        }
                    }
                    p {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }
    > * {
        background-color: #fff;
    }
}

// Modals
.modal-twilio {
    .search-area {
        .search-btn {
            left: 30px;
            top: 25px;
        }
        input {
            padding-left: 30px;
        }
    }
}

.modal-twilio.modal-twilio-new-conversation {

    .phone-number-container > *:first-child {
        width: 20%;
        margin-right: 10px;
    }
}

// Custom button icons
.btn-icon-info-plain {
    background-color: unset;
    border: unset;
    color: #41BA77;
    cursor: pointer;
}

// Custom buttons
.btn-edit-plain {
    color: #41BA77;
    background-color: unset;
    border: unset;
    text-align: right;
    padding: 0px 16px;
    cursor: pointer;

    &:hover {
        color: #3AA56D;
    }
}
.input-editable-otf-plain {
    text-align: center;
    font-weight: 500;
    border: unset;
    width: min-content;
    text-overflow: ellipsis;
    width: 100%;
}