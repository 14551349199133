.cu-column-1 {
    width: 40%;
    min-width: 290px;
}

.cu-column-2 {
    width: 38%;
    min-width: 300px;
}

.cu-column-3 {
    width: 20%;
    min-width: 165px;
}

.cu-column-4 {
    width: 26%;
    min-width: 223px;
}

.cu-column-5 {
    width: 16%;
    min-width: 150px;
}

.cu-head {
    border-bottom: 1px solid $gray-E9EAEE;
    padding-right: 24px;
}

.cu-th {
    padding: 12px 24px;
    font-weight: 500;
}

.cu-td {
    padding: 0 24px;

    .badge {
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

.cu-body {
    max-height: calc(100vh - 440px);
}

.cu-row {
    background-color: $white;
    border: 1px solid transparent;
    border-radius: 6px;

    +.cu-row {
        margin-top: 8px;
    }

    &:hover,
    &.has-open {
        border-color: $gray-E9EAEE;
        box-shadow: $dropdown-box-shadow;
        cursor: pointer;
    }

    .cu-td {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.cu-responsive {
    overflow-x: auto;
    overflow-y: hidden;
}

.cu-user-block {
    +.cu-user-block {
        margin-top: 16px;
    }
}

.cu-photo-outer {
    min-width: 32px;
    position: relative;
}

.cu-photo {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background-color: #E3E5E8;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    em {
        font-size: 20px;
    }
}

.cu-table {
    min-width: 1200px;

    .cu-photo {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }

    .text-end {
        .text-muted {
            color: $gray-500 !important;
        }
    }
}

.cu-center {
    text-align: center;
}

.cu-body-tr {
    padding: 12px 24px 12px 0;

    +.cu-body-tr {
        border-top: 1px solid $gray-E9EAEE;
    }
}

.cu-tab-wrap {
    margin-left: -$modal-inner-padding;
    margin-right: -$modal-inner-padding;
    padding: 0 $modal-inner-padding;
    box-shadow: $dropdown-box-shadow;
    background-color: $white;
}

.cu-alert {
    border: 1px solid #F59E0B;
    background-color: #FFFBEB;
    padding: 15px 24px;
    color: #B45309;
    border-radius: 6px;
}

body {
    .ant-steps-horizontal {
        &.create-steps {
            margin-bottom: 20px;
            padding-bottom: 16px;
            margin-left: -$modal-inner-padding;
            margin-right: -$modal-inner-padding;
            padding-left: $modal-inner-padding;
            padding-right: $modal-inner-padding;
            border-bottom: 1px solid $gray-E9EAEE;
            width: auto;
        }
    }
}

.very-info-block {
    +.very-info-block {
        margin-top: 24px;
    }

    sup {
        top: 0;
    }
}

.user-permission {
    padding: 0 24px;
}

.uds-block {
    padding: 20px;
    border: 1px solid $gray-E9EAEE;
    border-radius: 8px;
    margin-bottom: 36px;
}

.numberof-days {
    .form-select {
        width: 104px;
    }
}