// Tanner Fry
// tfry@monetagroup.com
// General Styles for the application.

// MUI Theme Overrides
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    background-color: #41BA77;
    border: 1px solid #41BA77;
    box-shadow: none;

    &:hover {
        background-color: #5ec48b;
        border: 1px solid #5ec48b;
        box-shadow: none;
    }
}

// Modals
.modal-general-error {

    .modal-title {

        .icon-32 {
            color: #db5c5c;
        }
    }
}

// Spinners
.loading-spinner-container {
    // position: relative;
    // height: 100%;
    // width: 100%;

    .loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: unset;
        z-index: 9999;

        .spinner-container {
            background-color: #284254;
            border-radius: 5px;
            border: 1px solid #243b4c;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1em;

            .spinner {
                width: 48px;
                height: 48px;
                border: 5px solid #41BA77;
                border-bottom-color: transparent;
                border-radius: 50%;
                display: inline-block;
                box-sizing: border-box;
                animation: rotation 1s linear infinite;
            }
        }
        
        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        } 
    }
    .background-dimmer {
        background-color: #000;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9998;
    }
}