@include media-breakpoint-up(sm) {
    .modal-dialog {
        margin-left: auto;
        margin-right: auto;
    }
}


@include media-breakpoint-up(md) {
    .fs-md-20 {
        font-size: 20px !important;
    }

    .border-bottom-md-0 {
        border-bottom: none !important;
    }

    .card {
        &.unread-card {
            margin-bottom: 0;
        }
    }

    .fs-md-18 {
        font-size: 18px;
    }

    .lh-md-32 {
        line-height: 32px;
    }

    .border-md-top {
        border-top: 1px solid $gray-E9EAEE !important;
    }

    .share-table {
        .ant-table-thead {
            > tr {
                > th {
                    &:last-child {
                        padding: 0;
                    }
                }
            }
        }

        .ant-table-tbody {
            > tr {
                position: relative;

                > td {
                    &:last-child {
                        padding: 0;
                        position: static;
                    }
                }

                &:hover {
                    > td {
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            color: transparent;
                        }
                    }

                    .table-action {
                        display: flex;
                    }
                }
            }
        }
    }

    .aside-bar {
        .featured-row {
            .featured-item {
                .featured-content {
                    .ftr-lbl {
                        background-color: $gray-100;
                    }
                }
            }
        }
    }
}


@include media-breakpoint-up(xl) {
    .advisor-vh-height {
        height: calc(100vh - 211px);
        min-height: 1300px;
    }

    .priority-card {
        height: calc(50% - 24px);

        .card-body {
            height: calc(100% - 100px);
        }
    }

    .unread-card {
        height: 50%;

        .card-body {
            height: calc(100% - 113px);
        }
    }
}


@include media-breakpoint-down(xxl) {
    .share-table {
        .table-action {
            left: 30%;
            padding-right: 50px;
        }
    }
}


@include media-breakpoint-down(xl) {
    .priority-card {
        .priority-block {
            max-height: 450px;
        }
    }

    .unread-card {
        .msg-list {
            max-height: 450px;
        }
    }

    .share-table {
        .table-action {
            left: 25%;
            padding-right: 30px;

            a {
                padding: 0 8px;
                font-size: 14px;
            }
        }
    }

    .aside-area {
        display: block;
        max-height: none;

        .aside-bar {
            max-width: 100%;
            min-width: auto;
        }
    }
}


@include media-breakpoint-down(lg) {
    .client-user-left {
        margin-bottom: 12px;
    }

    .menuicon,
    .header-right {
        min-width: 70px;
        color: white;
    }

    .content-right {
        width: 100%;
    }

    .sidebar {
        transform: translateX(-100%);
        transition: all 0.5s ease-in-out;
    }

    .sidebar-outer {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1111;
        opacity: 0;
        visibility: hidden;

        .mobilemenu-overlay {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            cursor: pointer;
            background-color: rgba(23, 41, 53, 0.5);
        }

        &.open {
            opacity: 1;
            visibility: visible;

            .sidebar {
                transform: translateX(0);
            }

            .mobilemenu-overlay {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .sidemenu-wrap {
        height: calc(100vh - 48px);
    }

    .tab-wrap {
        padding: 0;
    }

    .nav-tabs {
        flex-wrap: nowrap;

        li {
            width: 100%;
        }

        .nav-link {
            text-align: center;
            width: 100%;
        }

        &.sign-tabs {
            li {
                width: auto;
            }
        }
    }
}


@include media-breakpoint-down(md) {
    header {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    h3, .h3 {
        font-size: 18px;
    }

    .login-section {
        padding-top: 16px;
        background-image: none;
    }

    .login-logo {
        max-width: 100px;
    }

    .nav-link {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
    }

    .nav-tabs {
        .nav-link {
            line-height: 24px;
        }
    }

    .content-area {
        padding: 0 0 24px;
        height: calc(100vh - 89px);
    }

    .share-card {
        .card-header {
            position: relative;
        }
    }

    .share-items-selected {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        padding-top: 27px;
        padding-bottom: 27px;
    }

    .modal-footer {
        padding-top: 15px;
        border-top-width: 1px;
        display: block;

        .btn {
            width: 100%;

            + .btn {
                margin-left: 0;
                margin-top: 16px;
            }
        }
    }

    .modal-header {
        .btn-close {
            top: 24px;
        }
    }

    .modal-header {
        padding-bottom: 16px;
        border-bottom-width: 1px;
    }

    .confirm-modal {
        .modal-header {
            padding-bottom: 0;
            border-bottom-width: 0;
        }

        .modal-footer {
            padding-top: 0;
            border-top-width: 0;
        }

        .modal-title {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .modal-full {
        .modal-header {
            justify-content: center;
        }

        .modal-dialog {
            margin: 0;
            max-width: 100%;
            width: 100%;
        }

        .modal-content {
            border-radius: 0;
            min-height: 100vh;
        }
    }

    .upload-modal {
        .modal-body {
            display: flex;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .upload-area {
        height: auto;
        width: 100%;
    }

    .copy-modal {
        .breadcrumb {
            padding: 12px 24px;
        }

        .modal-body {
            padding-top: 16px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .copy-item-table {
        border: none;
        border-top: 1px solid $gray-E9EAEE;
        padding: 0;
        border-radius: 0;
    }

    .share-tool-right {
        position: relative;
        z-index: 2;

        > .btn {
            + .btn {
                margin-left: 0;
            }
        }
    }

    .tab-icon {
        margin-left: auto;
        margin-right: auto;
    }

    .share-table {
        .table-action {
            display: none !important;
        }
    }

    .custom-table {
        .ant-table-tbody,
        .ant-table tfoot {
            > tr {
                > td {
                    padding: 12px 24px;
                }
            }
        }
    }

    body {
        .ant-notification {
            margin-right: 0;
            bottom: 24px !important;
            top: auto !important;
            left: 16px !important;
            right: 16px !important;
        }

        .ant-notification-notice {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            max-width: calc(100vw - 16px * 2);
        }
    }

    .modal-title {
        font-size: 18px;
        line-height: 24px;
    }

    .view-doc-side {
        display: none;
    }

    .view-doc-head {
        padding: 16px 16px;

        .breadcrumb {
            display: none;
        }
    }

    .view-footer-left {
        display: none;
    }

    .pdf-name {
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .share-tool-left {
        .breadcrumb {
            flex-wrap: nowrap;
        }
    }

    .sign-modal {
        .modal-body {
            padding-top: 0;
            border-top: none;
            padding-bottom: 0;
        }

        .border-bottom {
            border-bottom: none !important;
        }
    }

    .filtbyclient-modal {
        .modal-body {
            padding-top: 0;
            border-top: none;
        }
    }

    .agre-search-dropdown {
        padding-top: 12px;
    }

    .agre-search-list {
        min-width: auto;
    }

    .check-list {
        max-height: none;
    }

    .short-sign {
        min-width: 60px;
    }

    .notific-block {
        p {
            font-size: 14px;
        }
    }

    .message-dropdown {
        min-width: 260px;
    }

    .dash-page {
        .featured-row {
            .featured-item {
                max-width: 100%;
            }
        }

        .featured-section {
            .featured-row {
                .featured-item {
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    margin-bottom: 0;
                    background-color: $white;
                    border-radius: 0;

                    .ftr-caption {
                        p {
                            display: none;
                        }
                    }

                    .thumb-img {
                        width: 84px;
                        height: 56px;
                    }
                }

                &.full-row {
                    .thumb-img {
                        width: 100%;
                        height: auto;
                    }
                }

                [class*="col-"] {
                    &:nth-child(odd) {
                        .featured-item {
                            background-color: $gray-400;
                        }
                    }
                }
            }
        }
    }

    .aside-area {
        .aside-bar {
            max-width: none;
        }
    }

    .aside-bar {
        .custom-style-scroll {
            overflow: visible;
        }

        .featured-row {
            .featured-item {
                border-top: none;
                border-left: none;
                border-right: none;
                margin-bottom: 0 !important;
                background-color: $white;
                border-radius: 0;

                &:nth-child(odd) {
                    background-color: $gray-400;
                }
            }
        }
    }

    .featured-content {
        h2 {
            font-size: 24px;
            line-height: 32px;
        }
    }

    .mx-n24 {
        margin-left: -24px;
        margin-right: -24px;
    }

    // .priority-card {
    //     .priority-block {
    //         max-height: none;
    //     }
    // }

    // .unread-card {
    //     .msg-list {
    //         max-height: none;
    //     }
    // }

    .prty-btn {
        width: auto;
        padding-left: 0;
    }

    .prty-caption {
        max-width: calc(100% - 150px);
    }

    .msg-list {
        .msg-item {
            display: block;

            .msg-caption {
                margin-top: 12px;
                padding-left: 0;
                max-width: 100%;
                align-items: flex-start;
            }
        }
    }

    .prty-item-body {
        position: fixed;
        z-index: 11;
        top: 0;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: $white;
        overflow: auto;
        padding: 0;
        display: none;

        .card {
            box-shadow: none;
        }

        .textarea-88 {
            height: 164px;
        }
    }

    .task-noresult {
        height: 326px;
    }

    .aside-area {
        .ftr-detailsarea {
            img {
                width: 100%;
            }
        }
    }

    .ftr-caption {
        .description {
            word-break: break-all;
        }
    }

    .user-permission {
        padding: 0;
    }
}


@media (max-width: 575px) {
    .totext {
        left: 50%;
        right: auto;
        top: 45px;
        transform: translateX(-50%);
    }

    .totext-center {
        left: 50%;
        right: auto;
        top: 75px;
        transform: translateX(-50%);
    }
}

@media (max-width: 480px) {
    .heading-calender {
        .Calendar {
            left: -65px;
        }
    }

    .social-share {
        li {
            font-size: 12px;
        }
    }

    .share-icon {
        width: 46px;
        height: 46px;
    }
}

@include media-breakpoint-down(lg) {
.responsive-iframe {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
